import { graphql, navigate, withPrefix } from "gatsby";
import { getUserLangKey } from "ptz-i18n";
import React from "react";

interface Props {
    data: unknown;
}

const RedirectIndex = ({ data }: Props): JSX.Element => {
    // Skip build, Browsers only
    if (typeof window !== "undefined") {
        const { langs, defaultLangKey } = data.site.siteMetadata;
        const langKey = getUserLangKey(langs, defaultLangKey);
        const homeUrl = withPrefix(`/${langKey.split("-")[0]}/`);
        navigate(homeUrl);
    }
    return <div />;
};

export default RedirectIndex;

export const pageQuery = graphql`
    query IndexQuery {
        site {
            siteMetadata {
                defaultLangKey
                langs
            }
        }
    }
`;
